<template>
  <div class="expandable-block">
    <button
      @click="onExpandBlock()"
      :disabled="expandedDefault"
      :class="{ 'expandable-block__expand-bth--expanded': show }"
      class="expandable-block__expand-bth"
    >
      <span>{{ title }}</span>
      <svg
        v-if="!expandedDefault"
        :class="{ 'expandable-block__expand-icon--expanded': show }"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="expandable-block__expand-icon"
      >
        <path
          d="M4.5 8.25L12 15.75L19.5 8.25"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <div v-show="show || expandedDefault" class="expandable-block__dropdown">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpandableBlock',

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    modelValue: {
      type: Boolean,
      default: false,
    },

    expandedDefault: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (value) {
          this.$emit('expand');
        }

        this.$emit('update:modelValue', value);
      },
    },
  },

  methods: {
    onExpandBlock() {
      if (this.expandedDefault) return;

      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
.expandable-block__expand-bth {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #989898;
	user-select: none;
  transition: all 0.3s;

  &:disabled {
    cursor: default;
  }

  &--expanded {
    color: var(--blue);
  }
}

.expandable-block__expand-icon {
  transition: transform 0.3s;

  &--expanded {
    transform: rotate(180deg);
  }
}

.expandable-block__dropdown {
  padding-top: 16px;
}
</style>
